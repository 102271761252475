import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Image,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit, useParams,useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import signInImage from "../../../assets/images/newcropped.png";
import {
  AppContext,
  PosFormButton,
  PosInput,
  PosMainProgress,
  PosTostMessage,
  PosLable
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { publicRewardCardUpdateData } from "../AuthService";
import octoposLogo from "../../../assets/images/cropped_logo.png";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";


const PublicRewardCardUpdate = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const location = useLocation();
  const actionResponse = useActionData();
  const [businessImage, setBusinessImage] = useState("");
  // const loaderResponse = useLoaderData();
  const [locationData, setLocationData] = useState([]);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [locPhone, setLocPhone] = useState("");
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const [date, setDate] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key1 = queryParams.key1;
  obj.key2 = queryParams.key2;
  obj.count = 0;
  const [loading, setLoading] = useState(true);
  const [loaderResponse, setLoaderResponse] = useState([]);
  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();
  const [custField, setCustField] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  const handleInputChange = (index, newValue) => {
    const updatedCustField = [...custField];
    updatedCustField[index].value = newValue;
    setCustField(updatedCustField);
    setValidationErrors((prevErrors) => {
      return {
        ...prevErrors,
        [index]: "",
      };
    });
  };
  const formatDate = (date) => {
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const dd = date.getDate().toString().padStart(2, "0");
    const yyyy = date.getFullYear().toString();
    return `${mm}/${dd}/${yyyy}`;
  };
  const validateField = (value, field) => {
    if (
      field.field_required === "yes" &&
      field.field_display === "yes" &&
      !value
    ) {
      return `${field.field_name} is required`;
    }
    if (field.field_type === "text" && value && value.length > 191) {
      return `${field.field_name} cannot exceed 191 characters`;
    }
    if (field === "date" && (value === "" || value === null)) {
      return `${field} is required`;
    }
    if (field.field_type === "number" && value) {
      const numericValue = Number(value);
      if (isNaN(numericValue)) {
        return `${field.field_name} must be a number`;
      } else if (numericValue > 999999) {
        return `${field.field_name} cannot exceed 999999`;
      } else if (!Number.isInteger(numericValue)) {
        return ` Please enter a valid ${field.field_name}`;
      }
    }
    return "";
  };

  const handleBlur = (index, value, field) => {
    const error = validateField(value, field);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [index]: error,
    }));
  };

  const effectRun = useRef(true);

  useEffect(() => {
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessImage(octoposLogo);
    } else {
      setBusinessImage(octoposLogo);
    }
  }, []);

  useEffect(() => {
    if (effectRun.current === true) {
      myContext.handleLoading(true);
      fetchData();
      // return () => {
      effectRun.current = false;
      // };
    }
  }, []);

  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key1: queryParams.get("key1"),
      key2: queryParams.get("key2"),
      count: 0,
    };
    setLoading(true);
    try {
      publicRewardCardUpdateData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLocationData(response.data?.data);
            setLoaderResponse(response);
            if (
              null != response.data?.data?.image_url &&
              "" != response.data?.data?.image_url
            ) {
              setLocImgNotFound(0);
            }
            if (
              null != response.data?.data?.business_name &&
              "" != response.data?.data?.business_name
            ) {
              setBusinessNameNotFound(0);
            }
            let newVal = response.data?.data?.location_data.phone
              .toString()
              .replace(/\D/g, "");
            if (
              response.data?.data?.location_data.phone.toString().length ===
              0
            ) {
              newVal = "";
            } else if (
              response.data?.data?.location_data.phone.toString().length <=
              3
            ) {
              newVal = newVal.replace(/^(\d{0,3})/, "($1)");
            } else if (
              response.data?.data?.location_data.phone.toString().length <=
              6
            ) {
              newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
            } else if (
              response.data?.data?.location_data.phone.toString().length <=
              10
            ) {
              newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
              );
            } else {
              newVal = newVal.substring(0, 10);
              newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
              );
            }
            setLocPhone(newVal);
            const formattedCustomFields =
            response.data?.data?.custom_fields.map((field) => ({
              ...field,
              value:
                field.field_type === "date" &&
                field.value !== undefined &&
                field.value !== "" &&
                field.value !== null
                  ? new Date(field.value)
                  : field.value || "",
            }));
          setCustField(formattedCustomFields);
            // formik.setFieldTouched("email", true);
            // formik.setFieldTouched("terms_conditions", true);
            // setHtmlData(response.data.data);
            // setAllLines(response.data.data.all_lines);
            // setRewardsData(response.data.data.rewards_data);
            // setTaxCollected(response.data.data.taxes_collected);
            // setGetCouponData(response.data.data.generic_coupon_dtls);
            // // setGiftCardData(response.data.data);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            // setGiftCardData([]);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // setGiftCardData([]);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      // setGiftCardData([]);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

const formik = useFormik({
  initialValues: {
    email: loaderResponse.data?.data?.email || "",
    first_name: loaderResponse.data?.data?.first_name || "",
    zipcode: loaderResponse.data?.data?.zipcode || "",
    reward_card_number: loaderResponse.data?.data?.reward_card?.phone || "",
    terms_conditions: false,
    birth_day: loaderResponse.data?.data?.birth_day || "",
  },
  enableReinitialize:true,
  validationSchema: Yup.object({
    reward_card_number: Yup.number()
      .required(Constants.PHONE_IS_REQUIRED)
      .typeError(Constants.PHONE_NOT_VALID)
      .integer(Constants.PHONE_NOT_VALID)
      .min(100, Constants.PHONE_MIN_VALUE)
      .max(9999999999, Constants.PHONE_MAX_VALUE),
    email: Yup.string()
      .trim()
      .required(Constants.USER_EMAIL_REQUIRED)
      .email(Constants.USER_EMAIL_NOT_VALID)
      .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
      .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
    first_name: Yup.string()
      // .trim()
      .nullable()
      .max(191, Constants.REWARD_CARD_FIRST_NAME_LENGTH_GREATE_THEN_255),
    zipcode: Yup.number()
      .integer(Constants.LOCATION_ZIP_VALID)
      .typeError(Constants.LOCATION_ZIP_VALID)
      .min(9, Constants.LOCATION_ZIP_MUST_BE_2_DIGITS)
      .max(9999999999, Constants.LOCATION_ZIP_MUST_BE_10_DIGITS),
    terms_conditions: Yup.bool()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Required"),
  }),
  onSubmit: (values) => {
    myContext.handleLoading(true);
    setButtonDisable(true);
    let payload = {
      ...values,
      reward_card_id:loaderResponse.data?.data?.reward_card?.id,
      birth_day:
        undefined != values.birth_day &&
        null != values.birth_day &&
        "" != values.birth_day
          ? moment(values.birth_day).format("MM/YYYY")
          : "",
    };
    custField.forEach((field) => {
      if (field.field_type === "date" && field.value) {
        field.value = formatDate(new Date(field.value));
      }
    });
    payload.custom_fields = JSON.stringify(custField);
    submit(payload, {
      method: Constants.POST,
      path: Constants.SIGNIN_PATH,
    });
  },
});

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        // addToast({
        //   alertStatus: Constants.TOAST_TYPE_SUCESS,
        //   alertTitle: "Reward Card Create",
        //   alertDescription: "Reward Card successfully created.",
        // });
        onRewardModalOpen();
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.email.length <= 0 ||
      formik.errors.email ||
      formik.values.reward_card_number.length <= 0 ||
      formik.errors.reward_card_number ||
      formik.errors.terms_conditions ||
      formik.errors.zipcode ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  const handleCloseDialog = () => {
    onRewardModalClose();
    window.open(window.location.href, "_self").close(); // Attempt to close the current tab
  };

  return(
  <Flex
    bg={Colors.loginAuthBackground}
    minHeight={"100vh"}
    flexDirection={"column"}
  >
    <Box
      w={"100%"}
      sx={{
        position: "-webkit-sticky",
        /* Safari */ position: "sticky",
        top: "0",
        zIndex: 999,
      }}
    >
      <Box
        w={"100%"}
        sx={{
          /* Safari */ position: "absolute",
          top: "0",
          zIndex: 99999999,
        }}
      >
        {myContext.loading ? <PosMainProgress /> : null}
      </Box>
      <Flex
        // h={"100vh"}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        alignSelf={"center"}
      >
      <Box
        mt={{ base: 10, md: 20, sm: 18, lg: 28 }}
        ml={{ base: 5, md: 10, sm: 8, lg: 18 }}
        mr={{ base: 5, md: 10, sm: 8, lg: 18 }}
        mb={{ base: 5, md: 10, sm: 8, lg: 18 }}
        bg={Colors.loginAuthBackground}
        style={{
          width: "54.13rem",
          borderRadius: "0.63rem",
          border: "0.13rem solid #FFF",
          boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
          paddingBottom: "1rem",
          boxSizing: "border-box",
        }}
      >
        <Box
          display="flex"
          mt={locationData.image_url != null &&
          "" != locationData.image_url ? "2rem" : "1rem"}
          justifyContent="center"
          alignItems="center"
        >
          {locationData.image_url != null &&
          "" != locationData.image_url ? (
            <Image
              style={{
                // width: "15.31rem",
                // marginTop: "4.69rem",
                height: "8.125rem",
              }}
              src={locationData.image_url}
              alt="Octopos"
            />
          ) : (
            null
            // <Image
            //   style={{
            //     width: "15.31rem",
            //     // height: "8.125rem",
            //   }}
            //   src={businessImage}
            //   alt="Octopos"
            // />
          )}
        </Box>
        <Box
          color={Colors.posTextInfo}
          mt={"0.625rem"}
          style={{
            fontSize: "1.5rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {businessNameNotFound == 0 && 0 === locImgNotFound && (
            <Text
            fontSize={locationData.image_url != null &&
              "" != locationData.image_url ? "1.13rem":"1.73rem"}
              style={{
                // fontSize: "1.13rem",
                fontStyle: "1.9rem",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {locationData.business_name}
            </Text>
          )}
          {businessNameNotFound == 0 && 1 == locImgNotFound && (
            <Text
            fontSize={locationData.image_url != null &&
              "" != locationData.image_url ? "1.13rem":"1.73rem"}
              style={{
                // fontSize: "1.13rem",
                fontStyle: "1.9rem",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {locationData.business_name}
            </Text>
          )}
        </Box>
        <Box
          mx={{ base: 4, md: 156 }}
          color={Colors.posTextInfo}
          style={{
            marginTop: "0.31rem",
            fontSize: "1.13rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
          textAlign="center"
        >
          <Text>
          {null != loaderResponse.data?.data?.location_data.address1 &&
          "" != loaderResponse.data?.data?.location_data.address1
            ? loaderResponse.data?.data?.location_data.address1 + ", "
            : ""}
          </Text>
          <Text>
          {null != loaderResponse.data?.data?.location_data.city &&
          "" != loaderResponse.data?.data?.location_data.city
            ? loaderResponse.data?.data?.location_data.city + ", "
            : ""}
          {null != loaderResponse.data?.data?.location_data.state &&
          "" != loaderResponse.data?.data?.location_data.state
            ? loaderResponse.data?.data?.location_data.state + ", "
            : ""}
          {null != loaderResponse.data?.data?.location_data?.zipcode &&
          "" != loaderResponse.data?.data?.location_data?.zipcode
            ? locationData.location_data?.zipcode + ". "
            : ""}
          </Text>
          {/* {null != loaderResponse.data?.data?.location_data.country &&
          "" != loaderResponse.data?.data?.location_data.country
            ? loaderResponse.data?.data?.location_data.country
            : ""}
          . */}
        </Box>
        <Box
          color={Colors.posTextInfo}
          style={{
            marginTop: "0.31rem",
            fontSize: "1.13rem",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Ph- {null != locPhone && "" != locPhone ? locPhone : "N.A."}
        </Box>
        <Box mx={{ base: 4, md: 156 }} textAlign="left">
          <Stack spacing={2}>
            <Text
              mt={{ base: 5, md: 5, sm: 4, lg: 7 }}
              color={Colors.posTextInfo}
              style={{
                fontSize: "0.88rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Please fill out all the required information to update your registration details.
              {" "}
              {loaderResponse.data?.data?.business_name != null &&
              loaderResponse.data?.data?.business_name != ""
                ? "at " + loaderResponse.data?.data?.business_name
                : ""}{" "}
            </Text>
            {/* <InputGroup
              bg={Colors.loginAuthInput}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "0.63rem",
              }}
            > */}
            {/* <InputLeftElement pointerEvents="none">
                <EmailIcon color={Colors.posNavbarLink} />
              </InputLeftElement> */}
            <PosInput
              id="reward_card_number"
              placeholder={"Phone Number*"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.reward_card_number}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "reward_card_number",
                  formik.values.reward_card_number.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              disabled = {true}
              inputType={"text"}
              inputError={
                formik.touched.reward_card_number &&
                formik.errors.reward_card_number
              }
              darkPlaceholder={true}
            >
              <PhoneIcon color="#5881FE" />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.reward_card_number &&
              formik.errors.reward_card_number ? (
                <span>{formik.errors.reward_card_number}</span>
              ) : null}
            </Text>
            {/* </InputGroup> */}
            <PosInput
              id="email"
              placeholder={"Email*"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.email}
              handleBlur={(e) => {
                formik.setFieldValue("email", formik.values.email.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"email"}
              inputError={formik.touched.email && formik.errors.email}
              darkPlaceholder={true}
            >
              <EmailIcon color="#5881FE" />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </Text>
            <PosInput
              id="first_name"
              placeholder={"Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.first_name}
              posInput={true}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "first_name",
                  formik.values.first_name.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              inputType={"text"}
              inputError={formik.touched.first_name && formik.errors.first_name}
              darkPlaceholder={true}
            />
            {/* <PhoneIcon color="#5881FE" /> */}
            {/* <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} /> */}
            <Text color={Colors.errorColor}>
              {formik.touched.first_name && formik.errors.first_name ? (
                <span>{formik.errors.first_name}</span>
              ) : null}
            </Text>
              <PosInput
                posInputGroup={true}
                id={"zipcode"}
                posNumberInput={true}
                inputType={"number"}
                placeholder={"Enter Zipcode"}
                inputValue={formik.values.zipcode || ""}
                handleBlur={formik.handleBlur}
                handleInputChange={formik.handleChange}
                inputError={formik.touched.zipcode && formik.errors.zipcode}
                darkPlaceholder={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <span>{formik.errors.zipcode}</span>
                ) : null}
              </Text>
            <Calendar
              placeholder="Birth Day (mm/yyyy)"
              value={formik.values.birth_day}
              onChange={(e) => formik.setFieldValue("birth_day", e.value)}
              view="month"
              dateFormat="mm/yy"
              maxDate={new Date()} // Sets the max date to today
            />
            {custField &&
            custField.map((project, index) => (
              <Box pt={2} key={index}>
                {project.field_type === "text" && (
                  <PosInput
                    posInput={true}
                    darkPlaceholder={true}
                    type="text"
                    placeholder={(project.field_required == 'yes' && project.field_display == 'yes') ? "Enter " + project.field_name+"*":"Enter " + project.field_name }
                    // placeholder={"Enter "}
                    inputValue={project.value}
                    inputError={validationErrors[index]}
                    handleBlur={() =>
                      handleBlur(index, custField[index].value, project)
                    }
                    handleInputChange={(e) =>
                      handleInputChange(index, e.target.value)
                    }
                  ></PosInput>
                )}
                {project.field_type === "number" && (
                  <PosInput
                    darkPlaceholder={true}
                    posNumberInput={true}
                    type="number"
                    handleBlur={() =>
                      handleBlur(index, custField[index].value, project)
                    }
                    inputError={validationErrors[index]}
                    placeholder={(project.field_required == 'yes' && project.field_display == 'yes') ? "Enter " + project.field_name+"*":"Enter " + project.field_name }
                    inputValue={project.value}
                    handleInputChange={(e) =>
                      handleInputChange(index, e.target.value)
                    }
                  ></PosInput>
                )}
                {project.field_type === "date" && (
                  <Calendar
                    iconPos="left"
                    inputStyle={{
                      paddingLeft: "1rem",
                      background: "#f6f8ff",
                      color: Colors.primeposTextInfo,
                      fontSize: "0.94rem",
                      fontWeight: 300,
                    }}
                    style={{
                      height: "2.50rem",
                      borderRadius: "0.625rem",
                      backgroundColor: Colors.primeCampCardBgColor,
                    }}
                    value={project.value}
                    placeholder={(project.field_required == 'yes' && project.field_display == 'yes') ? "Select " + project.field_name+"*":"Select " + project.field_name }
                    onChange={(e) => {
                      handleInputChange(index, e.value, "date");
                    }}
                    onBlur={() => handleBlur(index, project.value, project)}
                    showIcon
                    showButtonBar
                    yearRange={"2000:2030"}
                    readOnlyInput
                  />
                )}
                <Text color={Colors.errorColor}>{validationErrors[index]}</Text>
              </Box>
            ))}
            <Box mt={"2rem"}>
              <Checkbox
                id="terms_conditions"
                name="terms_conditions"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isChecked={formik.values.terms_conditions}
              >
                By signing up for this reward program, I agree to following{" "}
                {/* <a
                  href="https://octopos.com/privacy-policy/"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </a>{" "} */}
                 <a
                  target="_blank"
                  href={window.location.origin + Constants.PUBLIC_REWARD_CARD_MOBILE_SMS_PRIVACY_POLICY_PATH}
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </a>
                {" "}and{" "}
                <a
                  target="_blank"
                  href={window.location.origin + Constants.PUBLIC_REWARD_CARD_TERMS_SERVICES_PATH}
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                >
                  Terms of Services
                </a>
                {/* <a
                  href="https://octopos.com/terms-conditions/"
                  style={{
                    textDecoration: "underline",
                    color: "blue", // typical link color
                    cursor: "pointer",
                  }}
                  target="_blank"
                >
                  Terms & Conditions
                </a> */}
                .
              </Checkbox>
              <Text color={Colors.errorColor}>
                {formik.touched.terms_conditions && formik.errors.terms_conditions ? (
                  <span>{formik.errors.terms_conditions}</span>
                ) : null}
              </Text>
            </Box>
            <Button
              color={Colors.loginAuthBackground}
              bg={Colors.loginButtonBackground}
              onClick={formik.handleSubmit}
              // w={"100%"}
              isDisabled={
                formik.values?.email?.length <= 0 ||
                formik.errors.email ||
                formik.values.reward_card_number.length <= 0 ||
                formik.errors.reward_card_number ||
                formik.errors.terms_conditions ||
                formik.errors.zipcode ||
                Object.values(validationErrors).some((error) => !!error) ||
                buttonDisable
                  ? true
                  : false
              }
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0.63rem",
                flexShrink: 0,
                gap: "0.63rem",
                borderRadius: "0.63rem",
                border: "1px solid" + Colors.loginButtonBoarder,
                boxShadow: "0px 1.63rem 2.5rem 0px" + Colors.loginButtonShadow,
                opacity: 1,
              }}
            >
              Submit
            </Button>
            <Text mt={{ base: "1rem", md: "1.25rem" }}>
              By signing up you agree to receive recurring automated marketing
              messages at the number provided. Reply STOP to opt-out or
              unsubscribe. Message & data rates apply.
            </Text>
          </Stack>
        </Box>
      </Box>
      <>
        <Modal
          isOpen={isRewardModalOpen}
          onClose={onRewardModalClose}
          size={"lg"}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Success!</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              Welcome to {loaderResponse.data?.data?.business_name} Reward
              Program. Happy Savings!
            </ModalBody>
            <ModalFooter>
              <PosFormButton
                buttonText={"Close"}
                CancelButton={true}
                // ref={cancelRef}
                onClick={handleCloseDialog}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      </Flex>
    </Box>
  </Flex>
  );
};

export default RouteWithLayout(PublicRewardCardUpdate);
