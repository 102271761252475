import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  calculateDiscountedPrice,
  reverseCalculateDiscountedPrice,
  twofix,
} from "../../../helpers/utils/Utils";
// import ButtonGroup from '@mui/material/ButtonGroup';
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination, {
  tablePaginationClasses,
} from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { TreeTable } from "primereact/treetable";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import Colors from "../../../assets/colors/Colors";
import ProductImageNotFound from "../../../assets/images/mdi_image.png";
import ProductListEmptyImage from "../../../assets/images/product_list_empty.png";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosNoDataFound,
  PosOutlineButton,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosSwitchButton,
  PosTab,
  PosTostMessage,
  ProductView,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme } from "../../../theme";
import ProductCreate from "./ProductCreate";
import ProductDuplicate from "./ProductDuplicate";
// import ProductEdit from "./ProductEdit";
import ProductEditDrawer from "../../../components/drawer/ProductEditDrawer";
import {
  bulkUploadProductsGet,
  getProductListForDeactive,
  getProductListForEdit,
  getProductListForQuickEdit,
  printLargeSign,
  productSalePriceChange,
  quickEditProduct,
} from "./ProductService";

const ProductList = (props) => {
  const { addToast } = PosTostMessage();
  let disbled_btn = true;
  const myContext = useContext(AppContext);
  const [productData, setProductData] = useState([]);
  const [productDeactiveData, setProductDeactiveData] = useState([]);
  const [deactiveTotalCount, setDeactiveTotalCount] = useState(0);
  const stateData = useLocation(); // get backraking data as param
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [isExtraLarge] = useMediaQuery("(min-width: 1750)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isNameLarge] = useMediaQuery("(min-width: 1365px)");
  const [isNameMeduim] = useMediaQuery("(min-width: 1280px)");
  const [isNameSmall] = useMediaQuery("(min-width: 768px)");
  const [isSmall] = useMediaQuery("(max-width: 768px)");
  const [isCheckBoxSetLarge] = useMediaQuery("(min-width: 1590px)");
  const [isCheckBoxSetSmall] = useMediaQuery("(min-width: 768px)");
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // id: false,
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [formDisable, setFormDisable] = useState(false);
  const [quickButtonDisable, setQuickButtonDisable] = useState(false);
  const [bulkButtonDisable, setBulkButtonDisable] = useState(false);

  const [createFlag, setCreateFlag] = useState(false);
  const [duplicateFlag, setDuplicateFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [cashDiscountPercentage, setCashDiscountPercentage] = useState(0);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const effectRunListDropDown = useRef(true);

  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [prodCount, setProdCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.showFilter &&
      "" != stateData?.state?.showFilter
      ? stateData?.state?.showFilter
      : false
  );
  const [applyButtonClicked, setApplyButtonClicked] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.apply &&
      "" != stateData?.state?.apply
      ? stateData?.state?.apply
      : 0
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.limit &&
      "" != stateData?.state?.limit
      ? stateData?.state?.limit
      : 25
  );
  const [timeoutId, setTimeoutId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.filter &&
      "" != stateData?.state?.filter
      ? stateData?.state?.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.order_type &&
      "" != stateData?.state?.order_type
      ? stateData?.state?.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.order &&
      "" != stateData?.state?.order
      ? stateData?.state?.order
      : "id"
  );
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);

  const [selectedLocations, setSelectedLocations] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.locations &&
      "" != stateData?.state?.locations
      ? JSON.parse(stateData?.state?.locations)
      : []
  );
  const [selectedCategories, setSelectedCategories] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.categories &&
      "" != stateData?.state?.categories
      ? JSON.parse(stateData?.state?.categories)
      : []
  );
  const [selectedVendors, setSelectedVendors] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.vendors &&
      "" != stateData?.state?.vendors
      ? JSON.parse(stateData?.state?.vendors)
      : []
  );

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.page &&
      "" != stateData?.state?.page
        ? stateData?.state?.page
        : 0,
    pageSize:
      undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.limit &&
      "" != stateData?.state?.limit
        ? stateData?.state?.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.order &&
        "" != stateData?.state?.order
          ? stateData?.state?.order
          : "id",
      sort:
        undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.order_type &&
        "" != stateData?.state?.order_type
          ? stateData?.state?.order_type
          : "desc",
    },
  ]);

  const sortRef = useRef(sortModelDeactive);
  const sortRefDeactive = useRef(sortModelDeactive);

  const [changeSalePrice, setChangeSalePrice] = useState("");
  const [changeSalePriceError, setChangeSalePriceError] = useState("");
  const columns = [
    { field: "name", header: "Product Name" },
    { field: "barcode", header: "Barcode" },
    { field: "reward_points", header: "Reward Points" },
    { field: "sale_price", header: "Retail Price($)" },
  ];
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [rewardErrorText, setErrorRewardText] = useState("");
  const [priceErrorText, setErrorSalePriceText] = useState("");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const deaciveListColumnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      ...actionColumnXS,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Primary Barcode",
      field: "barcode",
      sortable: true,
      ...actionColumnXL,
    },
    {
      headerName: "SKU",
      field: "sku",
      sortable: true,
      ...actionColumnSM,
    },
    {
      headerName: "In Stock Qty",
      field: "in_stock_qty",
      sortable: true,
      ...actionColumnLG,
    },
    {
      headerName: "Retail Price($)",
      field: "sale_price",
      sortable: true,
      ...actionColumnLG,
    },
    {
      headerName: "Location",
      field: "location",
      sortable: false,
      ...actionColumnMD,
    },
    {
      headerName: "Reactive",
      field: "reactive",
      width: 150,
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          // backtracking
          setButtonDisable(true);
          if (
            deactiveProductData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PRODUCT_LIST_PATH,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const tabNames = ["Active", "Inactive"];

  const [state, setState] = useState({
    formData: {},
    firstLayout: false,
    show: false,
  });

  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [locationId, setLocationId] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [lastDataLength, setLastDataLength] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(25);

  const [nodes, setNodes] = useState([]);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState([]);
  const [id, setId] = useState(0);
  const [bulkData, setBulkData] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSalePriceChangeOpen,
    onOpen: onSalePriceChangeOpen,
    onClose: onSalePriceChangeClose,
  } = useDisclosure();
  const {
    isOpen: isQuickEditOpen,
    onOpen: onQuickEditOpen,
    onClose: onQuickEditClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDuplicateOpen,
    onOpen: onDuplicateOpen,
    onClose: onDuplicateClose,
  } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const btnRef = React.useRef();
  const [expandedKeys, setExpandedKeys] = useState(null);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setSelectedNodeKeys([]);
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PRODUCT_MODULE,
            alertDescription: Constants.PRODUCT_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveProductData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PRODUCT_MODULE,
            alertDescription: Constants.PRODUCT_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    setPage(0);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);

    setTimeoutId(newTimeoutId);
  };

  const showDropDown = () => {
    setShowFilter((prevState) => {
      // Toggle the state
      const nextState = !prevState;
      // Conditionally set another state based on the previous state value
      if (
        prevState &&
        (selectedLocations.length > 0 ||
          selectedCategories.length > 0 ||
          selectedVendors.length > 0)
      ) {
        setPage(0);
        // Set other state based on prevState being false
        setSelectedLocations([]);
        setSelectedCategories([]);
        effectRun.current = true;
        setSelectedVendors([]);
      }
      return nextState; // Return the toggled state
    });
    // setShowFilter(prevState => !prevState); // Toggle the state
  };

  const handleClear = () => {
    // clear input value and get all data
    setSearchQuery("");
    setSortColumn("id");
    effectRun.current = true;
    setSortField(null);
    setSortOrder(null);
    setSortType("desc");
    props.navigate(Constants.PRODUCT_LIST_PATH); // refresh page time param data clear
  };

  const handleChangePage = (event, newPage) => {
    effectRun.current = true;
    setPage(newPage);
    setLoading(true);
    // fetchData();
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    effectRun.current = true;
    setPage(0);
    setLoading(true);
  };

  const handleSalePriceChange = (event) => {
    const inputSalePriceValue = event.target.value.trim();
    if (inputSalePriceValue >= 0.01 && inputSalePriceValue <= 999999.99) {
      setChangeSalePrice(inputSalePriceValue);
      setChangeSalePriceError("");
    } else {
      setChangeSalePriceError(Constants.PRODUCT_SALE_PRICE_MIN_MAX_ERROR);
    }
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
    setApplyButtonClicked(1);
    // fetchData();
  };

  const clearDropDown = () => {
    setSearchQuery("");
    setLoading(true);
    setSelectedLocations([]);
    setSelectedCategories([]);
    effectRun.current = true;
    setSelectedVendors([]);
    setApplyButtonClicked(0);
    // setSortField(null);
    // setSortOrder(null);
    // fetchData();
    props.navigate(Constants.PRODUCT_LIST_PATH);
  };

  const handleSelectionChange = (e) => {
    setSelectedNodeKeys(e.value);
  };

  // Example function to retrieve custom data based on selected value

  const changeSalePricePopUpOpen = () => {
    let selectedProd = [];
    if (Object.keys(selectedNodeKeys).length > 0) {
      Object.entries(selectedNodeKeys).forEach(([key, value]) => {
        if (value.checked === true || value.checked == true) {
          selectedProd.push(key);
        }
      });

      let payload = {
        selectedProductFromTree: selectedProd,
      };
      // setButtonDisable(true);
      myContext.handleLoading(true);
      setErrorRewardText("");
      setErrorSalePriceText("");
      bulkUploadProductsGet(payload)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const outputObject = Object.keys(response.data.data).map((key) => {
              const parentData = response.data.data[key][0];
              const children = response.data.data[key].map((item) => ({
                key: item.id.toString(),
                // data: item,
                data: {
                  name: item.location,
                  barcode: item.categories,
                  reward_points: item.reward_points,
                  sale_price: parseFloat(item.sale_price).toFixed(2),
                  cash_price:
                    item?.cash_discount_percentage > 0
                      ? reverseCalculateDiscountedPrice(
                          item.sale_price,
                          item?.cash_discount_percentage
                        )
                      : item.sale_price,
                  id: item.id,
                  price_error: "",
                  reward_error: "",
                  cash_discount_percentage: item?.cash_discount_percentage,
                },
                children: [],
              }));
              const isParentExpanded = children.length > 0;

              // Update expanded keys state for parent node
              setExpandedKeys((prevExpandedKeys) => ({
                ...prevExpandedKeys,
                [parentData.id.toString() + " "]: isParentExpanded,
              }));
              return {
                key: parentData.id.toString() + " ",
                data: {
                  ...parentData, // Include all properties from parentData
                  sale_price:
                    children.length > 1
                      ? " "
                      : parseFloat(parentData.sale_price).toFixed(2), // Assign the sale_price from parentData
                  cash_price:
                    children.length > 1
                      ? " "
                      : parseFloat(parentData.cash_price).toFixed(2), // Assign the sale_price from parentData
                  reward_points:
                    children.length > 1 ? " " : parentData.reward_points,
                },
                children: children.length > 1 ? children : [],
                expanded: isParentExpanded,
              };
            });
            setBulkData(outputObject);
            myContext.handleLoading(false);
            setChangeSalePrice("");
            setChangeSalePriceError("");
            onSalePriceChangeOpen();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } else {
      setButtonDisable(false);
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.PRODUCT_MODULE,
        alertDescription: "Please Select Product for bulk update.",
      });
    }
  };
  const clickIfapplyLoc = (loc) => {
    setSelectedLocations(loc);
    // if (
    //   loc.length == 0 &&
    //   selectedCategories?.length == 0 &&
    //   selectedVendors?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const clickIfapplyVen = (ven) => {
    setSelectedVendors(ven);
    // if (
    //   ven.length == 0 &&
    //   selectedLocations?.length == 0 &&
    //   selectedCategories?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const clickIfapplyCat = (cat) => {
    setSelectedCategories(cat);
    // if (
    //   cat.length == 0 &&
    //   selectedVendors.length == 0 &&
    //   selectedLocations?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const handleTabChange = (index) => {
    setSelectedLocations([]);
    setSelectedCategories([]);
    setSelectedVendors([]);
    setSelectedNodeKeys([]);
    setShowFilter(false);
    setSortColumn("id");
    setSortType("desc");
    // tab change time set currunt tab and pagination related clear all data
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      setSortField(null);
      setSortOrder(null);
      setPage(0);
      setRowsPerPage(25);
      effectRun.current = true;
      setLoading(true);
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setSortField(null);
      setSortOrder(null);
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      // setPage(0);
      // setRowsPerPage(25);
      effectRun.current = true;
      setLoading(true);
    }
  };

  const fetchDeActiveProductData = async () => {
    let data = {
      // limit: rowsPerPage,
      // page: page + 1,
      // order: sortColumn,
      // order_type: sortType,
      // filter: searchQuery,
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    try {
      getProductListForDeactive(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setProductDeactiveData(response.data.data.data);
            setDeactiveTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setProductDeactiveData([]);
            setDeactiveTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setProductDeactiveData([]);
          setDeactiveTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductDeactiveData([]);
      setDeactiveTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModelDeactive.page = 0;
    props.navigate(Constants.PRODUCT_LIST_PATH);
    setPage(0);
    // setRowsPerPage(25);
    if (curruntTab == 0) {
      if (null != searchQuery && "" != searchQuery) {
        setSortColumn("");
        effectRun.current = true;
        setSortType("");
      } else {
        setSortColumn("id");
        effectRun.current = true;
        setSortType("desc");
      }
      // fetchData();
    } else {
      fetchDeActiveProductData();
    }
    setLoading(true);
  };

  const fetchData = async () => {
    if (lastDataLength === 1 && page !== 1) {
      // when last page one product and click on deactive.
      var data = {
        limit: rowsPerPage,
        page: page === 0 ? 1 : page - 1,
        order: sortColumn,
        order_type: sortType,
        filter: searchQuery,
        cat_filter: selectedCategories,
        loc_filter: selectedLocations,
        ven_filter: selectedVendors,
      };
    } else {
      var data = {
        limit: rowsPerPage,
        page: page + 1,
        order: sortColumn,
        order_type: sortType,
        filter: searchQuery,
        cat_filter: selectedCategories,
        loc_filter: selectedLocations,
        ven_filter: selectedVendors,
      };
    }
    setLoading(true);
    try {
      getProductListForEdit(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // setTotalRecords(response.data?.data?.totalItems);
            if (response.data?.data?.data?.length > 0) {
              if (state.firstLayout) {
                setState({ ...state, firstLayout: false });
              }
            }
            const outputObject = response.data.data.data.map((item) => {
              const children = item.children.map((child) => ({
                data: {
                  id: child.id,
                  name: child.name,
                  barcode: child.barcode,
                  sale_price: child.sale_price,
                  // location_id: child.location_id,
                  image_url: child.image_url,
                  location: child.location,
                  categories: child.categories,
                  reward_points: child.reward_points,
                  parent: 0,
                },
                key: child.id,
              }));
              return {
                ...item,
                children,
              };
            });
            setNodes(outputObject);
            setProductData(response.data?.data?.data);
            setTotalRecords(response.data?.data?.totalItems);
            setLastDataLength(response.data?.data?.data?.length);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setTotalRecords(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
          //   searchInputHideRef.current = true;
          // }
          setProductData([]);
          setTotalRecords(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalRecords(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRunListDropDown.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse?.response?.data;
          if (
            actionData?.data?.location === "" ||
            actionData?.data?.location === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: Constants.LOCATION_NOT_FOUND,
            });
            props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
          } else if (
            actionData?.data?.category === "" ||
            actionData?.data?.category === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: Constants.PRODUCT_CATEGORY_NOT_FOUND,
            });
            props.navigate(Constants.CATEGORY_LIST);
          } else {
            let actionData = loaderResponse;
            error({ actionData });
          }
          effectRun.current = false;
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          if (
            loaderResponse.data?.data?.location === "" ||
            loaderResponse.data?.data?.location === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: Constants.LOCATION_NOT_FOUND,
            });
            props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
            effectRun.current = false;
          } else if (
            loaderResponse?.data?.data?.category === "" ||
            loaderResponse?.data?.data?.category === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: Constants.PRODUCT_CATEGORY_NOT_FOUND,
            });
            props.navigate(Constants.CATEGORY_LIST);
            effectRun.current = false;
          } else {
            const cat = loaderResponse?.data?.data?.category;
            const loc = loaderResponse?.data?.data?.location;
            const ven = loaderResponse?.data?.data?.vendors;

            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);

            const locationsDropDownData = loc?.map((loc) => ({
              label: loc.name,
              value: { name: loc.name, id: loc.id },
            }));
            setLocations(locationsDropDownData);
            if (locationsDropDownData.length == 1) {
              setOlColumnVisibilityModel((prev) => ({
                ...prev,
                location: false,
              }));
            }
            let vendorsDropDownData;
            if (null != ven && "" != ven) {
              vendorsDropDownData = ven?.map((vendor) => ({
                label: vendor.name,
                value: { name: vendor.name, id: vendor.id },
              }));
            } else {
              vendorsDropDownData = [];
            }
            setVendors(vendorsDropDownData);
            if (loaderResponse.data.data.products == 0) {
              setState({ ...state, firstLayout: true });
            } else {
              setState({ ...state, firstLayout: false });
            }
          }
        }
      }
      return () => {
        effectRunListDropDown.current = false;
      };
    }
  }, [loaderResponse]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveProductData();
      }
      effectRun.current = false;
    }
  }, [
    sortType,
    searchQuery,
    rowsPerPage,
    page,
    loading,
    sortColumn,
    selectedLocations,
    selectedCategories,
    selectedVendors,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  const handleEditPage = (item) => {
    myContext.handleLoading(true);
    setId(item.key);
    // onEditOpen();
    setEditFlag(true);
    setSelectedNodeKeys([]);
  };
  const printLargeSignButton = (item) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    setId(item.key);
    try {
      printLargeSign(item.key)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setButtonDisable(false);
            myContext.handleLoading(false);
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
          } else {
            // setFormDisable(false);
            setLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setButtonDisable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setButtonDisable(false);
      myContext.handleLoading(false);
    }
  };
  const handleViewPage = useCallback(
    (item) => {
      myContext.handleLoading(true);
      setId(item.key);
      onViewOpen();
    },
    [id]
  );
  const handleDuplicatePage = (item) => {
    myContext.handleLoading(true);
    setId(item.key);
    // onDuplicateOpen();
    setDuplicateFlag(true);
  };

  const handleQuickEditPage = (item) => {
    myContext.handleLoading(true);
    // setLoading(true);
    try {
      setId(item.key);
      setFormDisable(false);
      getProductListForQuickEdit(item.key)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // setLoading(false);
            myContext.handleLoading(false);
            setCashDiscountPercentage(
              response?.data?.data?.cash_discount_percentage
            );
            formik.setValues({
              name: response?.data?.data?.product?.name,
              barcode: response?.data?.data?.product?.barcode,
              sale_price: response?.data?.data?.product?.sale_price,
              cash_price:
                response?.data?.data?.cash_discount_percentage > 0
                  ? reverseCalculateDiscountedPrice(
                      response?.data?.data?.product?.sale_price,
                      response?.data?.data?.cash_discount_percentage
                    )
                  : response?.data?.data?.product?.sale_price,
              reward_points: response?.data?.data?.product?.reward_points,
              // edit_for_all_loc: true,
              edit_for_all_loc:
                response?.data?.data?.prod_count > 1 ? true : false,
            });
            setProdCount(response?.data?.data?.prod_count);
            setLocationId(response?.data?.data?.product?.location_id);
            setId(response?.data?.data?.product?.id);
            onQuickEditOpen();
          } else {
            // setFormDisable(false);
            // setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      // setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.PRODUCT_NAME_REQUIRED)
        .max(191, Constants.PRODUCT_NAME_LENGTH_GREATE_THEN_191),
      barcode: Yup.string()
        .required(Constants.PRODUCT_BARCODE_REQUIRED)
        .max(191, Constants.PRODUCT_BARCODE_LENGTH_GREATE_THEN_191),
      sale_price: Yup.number()
        .typeError(Constants.PRODUCT_RETAIL_PRICE_NOT_VALID_ERROR)
        .required(Constants.PRODUCT_RETAIL_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_RETAIL_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_RETAIL_PRICE_MAX_ERROR),
      cash_price: Yup.number()
        .typeError(Constants.PRODUCT_CASH_PRICE_NOT_VALID_ERROR)
        // .required(Constants.PRODUCT_CASH_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_CASH_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_CASH_PRICE_MAX_ERROR),
      reward_points: Yup.string()
        .matches(/^[0-9]*$/, Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR)
        .max(2147483647, Constants.PRODUCT_REWARD_POINTS_MAX_ERROR),
    }),
    onSubmit: async (values) => {
      setQuickButtonDisable(true);
      values.id = id;
      values.location_id = locationId;
      quickEditProduct(values)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Product Update",
              alertDescription: response.data.message,
            });
            setSelectedNodeKeys([]);
            setQuickButtonDisable(false);
            // formik.resetForm();
            myContext.handleLoading(false);
            setFormDisable(false);
            effectRun.current = true;
            fetchData();
            onQuickEditClose();
            // handleClose();
            //list refresh
          } else {
            setQuickButtonDisable(false);
            myContext.handleLoading(false);
            setFormDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setQuickButtonDisable(false);
          setFormDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });

  const options = {
    buttonName: "Edit",
    buttonIcon: <EditIcon />,
    handleClick: (item) => handleEditPage(item),
    subButtons: [
      {
        name: "Print large sign",
        disabledButton: buttonDisable,
        icon: <PictureAsPdfIcon />,
        onClick: (item) => printLargeSignButton(item),
      },
      // {
      //   name: "View",
      //   icon: <VisibilityIcon />,
      //   onClick: (item) => handleViewPage(item),
      // },
      {
        name: "Quick Edit",
        icon: <EditIcon />,
        onClick: (item) => handleQuickEditPage(item),
      },
      {
        name: "Deactive",
        disabledButton: buttonDisable,
        icon: <CloseIcon style={{ color: "red" }} />,
        onClick: (item) => handleDeactive(item),
      },
      {
        name: "Duplicate",
        icon: <ContentCopyIcon />,
        onClick: (item) => handleDuplicatePage(item),
      },
    ],
  };
  const actionTemplate = (row) => {
    return !row?.rowData?.more_data || row?.rowData?.more_data === 0 ? (
      <PosSpliteButton spliteOptions={options} item={row.rowData} />
    ) : null;
  };
  const actionTemplateReward = (row) => {
    return (
      <Box>
        <Input
          placeholder={row.rowData.data.reward_points}
          _placeholder={{ color: "#1D4FE7" }}
          style={{
            width: "50%",
            borderColor: "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
            // color:"#1D4FE7"
          }}
        ></Input>
        <Text color={Colors.errorColor}>{row.rowData.data.reward_error}</Text>
      </Box>
    );
  };
  const actionTemplateSalePrice = (row) => {
    return (
      <Box>
        <Input
          placeholder={row.rowData.data.sale_price}
          _placeholder={{ color: "#1D4FE7" }} // Change the color of the placeholder
          style={{
            width: "50%",
            borderColor: "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
            // color:"#1D4FE7"
          }}
        ></Input>
        <Text color={Colors.errorColor}>{row.rowData.data.price_error}</Text>
      </Box>
    );
  };
  const actionTemplateCashPrice = (row) => {
    return (
      <Box>
        <Input
          placeholder={row.rowData.data?.cash_price}
          _placeholder={{ color: "#1D4FE7" }} // Change the color of the placeholder
          style={{
            width: "50%",
            borderColor: "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
            // color:"#1D4FE7"
          }}
        ></Input>
        {/* <Text color={Colors.errorColor}>{row.rowData.data.price_error}</Text> */}
      </Box>
    );
  };
  const handleDeactive = (item) => {
    setButtonDisable(true);
    if (nodes?.length == 1 && page != 0) {
      setPage(page - 1);
    }
    myContext.handleLoading(true);
    let data = {
      id: item.key,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.PRODUCT_LIST_PATH,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };

  const handleReactive = (id) => {
    myContext.handleLoading(true);
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.PRODUCT_LIST_PATH,
    });
  };

  const deactiveProductData = productDeactiveData?.map((prod) => ({
    id: prod?.id,
    name: prod?.name,
    barcode: prod?.barcode,
    sku: null != prod?.sku && "" != prod?.sku ? prod.sku : "N.A.",
    in_stock_qty: prod?.in_stock_qty,
    sale_price: prod?.sale_price,
    location: prod?.location_with_trashed?.name,
  }));

  function TablePaginationActions(props) {
    const theme = createTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          style={{ color: Colors.posTextInfo }}
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{ color: Colors.posTextInfo }}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
          ) : (
            <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
          )}
        </IconButton>
        <IconButton
          style={{ color: Colors.posTextInfo }}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
          ) : (
            <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
          )}
        </IconButton>
        <IconButton
          style={{ color: Colors.posTextInfo }}
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon style={{ color: Colors.posTextInfo }} />
          ) : (
            <LastPageIcon style={{ color: Colors.posTextInfo }} />
          )}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const changeSalePriceSubmit = () => {
    setBulkButtonDisable(true);
    const pushedArray = [];
    let errorFound = false; // Define errorFound outside of the loop
    outerLoop: for (let singleProd of bulkData) {
      if (singleProd.children.length > 0) {
        for (let item of singleProd.children) {
          if (
            item.data.price_error.length > 0 ||
            item.data.reward_error.length > 0
          ) {
            errorFound = true;
            break outerLoop; // Exit the outerLoop
          } else {
            pushedArray.push(item.data);
          }
        }
      } else {
        if (
          singleProd?.data.price_error.length > 0 ||
          singleProd.data.reward_error.length > 0
        ) {
          errorFound = true;
          break outerLoop; // Exit the outerLoop
        } else {
          pushedArray.push(singleProd?.data);
        }
      }
    }

    if (errorFound) {
      setBulkButtonDisable(false);
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: "Product Bulk Update",
        alertDescription: "Retail price or reward points are not valid.",
      });
    } else {
      let payload = {
        selectedProds: pushedArray,
      };
      myContext.handleLoading(true);
      productSalePriceChange(payload)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: response.data.message,
            });
            myContext.handleLoading(false);
            fetchData();
            setBulkButtonDisable(false);
            setSelectedNodeKeys([]);
            onSalePriceChangeClose();
          } else {
            myContext.handleLoading(false);
            setBulkButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setBulkButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    }
  };

  const onSort = (event) => {
    setSelectedNodeKeys([]);
    const { sortField, sortOrder } = event;
    setSortField(sortField);
    setSortOrder(sortOrder); // sortOrder will be either "ascending" or "descending"
    setSortColumn(sortField);
    if (sortOrder == 1) {
      setSortType("asc");
    } else {
      setSortType("desc");
    }
    effectRun.current = true;
    setLoading(true); // Assuming you want to set loading state on sort change
  };

  const rewardPointsEditor = (options) => {
    return inputNumberEditor({ ...options, field: "reward_points" }); // Change field to 'quantity'
  };

  const inputNumberEditor = (options) => {
    return (
      <Box
        border={
          options.rowData["reward_error"]?.length > 0
            ? Colors.errorColor
            : "rgba(1, 0, 72, 0.20)"
        }
      >
        <InputText
          mode="decimal"
          value={options.rowData[options.field]}
          onChange={(e) =>
            onEditorRewardPointsValueChange(options, e.target.value)
          }
          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "50%",
            borderColor:
              options.rowData["reward_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
            color: "#1D4FE7",
          }}
          inputstyle={{
            color: "#1D4FE7 !important",

            // width: "50%",
            color: "#1D4FE7",
            borderColor:
              options.rowData["reward_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            // background: "#FFF",
          }}
        />
        {Number.isInteger(options.rowIndex) &&
        options.node.children.length > 0 ? null : (
          <Text color={Colors.errorColor}>
            {options.rowData["reward_error"]}
          </Text>
        )}
      </Box>
    );
  };

  const onEditorRewardPointsValueChange = (options, value) => {
    const key = options.node.key; // Get the key of the edited node
    const updatedNode = findNodeByKey(bulkData, key); // Find the node in bulkData
    // if(updatedNode.children.length == 0){}
    if (updatedNode) {
      // Update the sale_price value of the found node
      updateNodeRewardPoints(updatedNode, value);
      // Set the updated bulkData state
      setBulkData([...bulkData]); // Trigger re-render
    }
  };

  const findNodeByKey = (nodes, key) => {
    // Recursive function to find a node by key
    for (let node of nodes) {
      if (node.key === key) {
        return node; // Return the node if found
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findNodeByKey(node.children, key); // Recursively search children
        if (foundNode) {
          return foundNode; // Return the found node
        }
      } else {
        // console.log("Child change ");
      }
    }
    return null; // Node not found
  };

  const updateNodeRewardPoints = (node, value) => {
    // Update sale_price of the current node
    node.data.reward_points = value;
    if (isValidRewardPoints(value) && isPositiveInteger(value)) {
      setErrorRewardText("");
      node.data.reward_error = "";
    } else if (!value.trim()) {
      setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_REQUIRED);
      node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_REQUIRED;
    } else if (!isValidRewardPoints(value)) {
      setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_MAX_ERROR);
      node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_MAX_ERROR;
    } else if (!isPositiveInteger(value)) {
      setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR);
      node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR;
    }
    // Recursively update sale_price of all child nodes
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        updateNodeRewardPoints(child, value); // Recursive call for each child
      });
    } else {
      // if (isValidRewardPoints(value) && isPositiveInteger(value)) {
      //   setErrorRewardText("");
      //   node.data.reward_error = "";
      // } else if (!value.trim()) {
      //   setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_REQUIRED);
      //   node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_REQUIRED;
      // } else if (!isValidRewardPoints(value)) {
      //   setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_MAX_ERROR);
      //   node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_MAX_ERROR;
      // } else if (!isPositiveInteger(value)) {
      //   setErrorRewardText(Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR);
      //   node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR;
      // }
      // node.data.reward_error = Constants.PRODUCT_REWARD_POINTS_REQUIRED;
    }
  };

  const isValidRewardPoints = (value) => {
    // Regex pattern to match a valid decimal number within the specified range
    return parseFloat(value) >= 0 && parseFloat(value) <= 2147483647;
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const salePriceEditor = (options) => {
    return inputTextEditor({ ...options, field: "sale_price" }); // Change field to 'sale_price'
  };

  const inputTextEditor = (options) => {
    return (
      <>
        {/* <InputText
          mode="currency"
          currency="USD"
          locale="en-US"
          value={options.rowData[options.field]}
          onChange={(e) =>
            onEditorSalePriceValueChange(options, e.target.value)
          }
          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "50%",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
          inputStyle={{
            borderColor:
              options.rowData["reward_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
        /> */}
        <InputNumber
          // mode="decimal"
          mode="currency"
          currency="USD"
          locale="en-US"
          maxFractionDigits={2}
          value={options.rowData[options.field]}
          onChange={(e) => onEditorSalePriceValueChange(options, e.value)}
          // onValueChange={(e) =>
          //   onEditorSalePriceValueChange(options, e.value)
          // }

          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "50%",
            color: "#1D4FE7",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            // borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
          inputStyle={{
            width: "50%",
            color: "#1D4FE7",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
        />
        {Number.isInteger(options.rowIndex) &&
        options.node.children.length > 0 ? null : (
          <Text color={Colors.errorColor}>
            {options.rowData["price_error"]}
          </Text>
        )}
      </>
    );
  };

  const onEditorSalePriceValueChange = (options, value) => {
    const key = options.node.key; // Get the key of the edited node
    const updatedNode = findNodeByKey(bulkData, key); // Find the node in bulkData
    if (updatedNode) {
      // Update the sale_price value of the found node
      updateNodeSalePrice(updatedNode, value);
      // Set the updated bulkData state
      setBulkData([...bulkData]); // Trigger re-render
    }
  };

  const updateNodeSalePrice = (node, value) => {
    // Parse the value and round it to 2 decimal places
    const parsedValue = parseFloat(value);
    const roundedValue = isNaN(parsedValue) ? "" : parsedValue.toFixed(2);

    // Update sale_price of the current node
    node.data.sale_price = roundedValue;
    node.data.cash_price = reverseCalculateDiscountedPrice(
      roundedValue,
      node.data?.cash_discount_percentage
    );

    // Update error message based on the validity of the sale price
    if (isValidSalePrice(roundedValue)) {
      node.data.price_error = "";
    } else if (roundedValue === "") {
      setErrorRewardText("Error");
      node.data.price_error = Constants.PRODUCT_RETAIL_PRICE_REQUIRED;
    } else {
      setErrorRewardText("Error");
      node.data.price_error = Constants.PRODUCT_RETAIL_PRICE_MIN_MAX_ERROR;
    }

    // Recursively update children nodes
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        updateNodeSalePrice(child, roundedValue); // Pass down the same value to children
      });
    }
  };

  const cashPriceEditor = (options) => {
    return cashinputTextEditor({ ...options, field: "cash_price" }); // Change field to 'sale_price'
  };
  const cashinputTextEditor = (options) => {
    return (
      <>
        {/* <InputText
          mode="currency"
          currency="USD"
          locale="en-US"
          value={options.rowData[options.field]}
          onChange={(e) =>
            onEditorSalePriceValueChange(options, e.target.value)
          }
          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "50%",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
          inputStyle={{
            borderColor:
              options.rowData["reward_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
        /> */}
        <InputNumber
          // mode="decimal"
          mode="currency"
          currency="USD"
          locale="en-US"
          maxFractionDigits={2}
          value={options.rowData[options.field]}
          onChange={(e) => onEditorCashPriceValueChange(options, e.value)}
          // onValueChange={(e) =>
          //   onEditorSalePriceValueChange(options, e.value)
          // }

          onKeyDown={(e) => e.stopPropagation()}
          style={{
            width: "50%",
            color: "#1D4FE7",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            // borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
          inputStyle={{
            width: "50%",
            color: "#1D4FE7",
            borderColor:
              options.rowData["price_error"]?.length > 0
                ? Colors.errorColor
                : "rgba(1, 0, 72, 0.20)",
            borderWidth: 2,
            borderRadius: 10,
            background: "#FFF",
          }}
        />
        {Number.isInteger(options.rowIndex) &&
        options.node.children.length > 0 ? null : (
          <Text color={Colors.errorColor}>
            {/* {options.rowData["price_error"]} */}
          </Text>
        )}
      </>
    );
  };

  const onEditorCashPriceValueChange = (options, value) => {
    const key = options.node.key; // Get the key of the edited node
    const updatedNode = findNodeByKey(bulkData, key); // Find the node in bulkData
    if (updatedNode) {
      // Update the sale_price value of the found node
      updateNodeCashPrice(updatedNode, value);
      // Set the updated bulkData state
      setBulkData([...bulkData]); // Trigger re-render
    }
  };

  const updateNodeCashPrice = (node, value) => {
    // Parse the value and round it to 2 decimal places
    const parsedValue = parseFloat(value);
    const roundedValue = isNaN(parsedValue) ? "" : parsedValue.toFixed(2);

    // Update sale_price of the current node
    node.data.cash_price = roundedValue;
    node.data.sale_price = calculateDiscountedPrice(
      roundedValue,
      node.data?.cash_discount_percentage
    );
    // // Update error message based on the validity of the sale price
    if (isValidSalePrice(roundedValue)) {
      node.data.price_error = "";
      // }
      // else if (roundedValue === "") {
      //   setErrorRewardText("Error");
      //   node.data.price_error = Constants.PRODUCT_CASH_PRICE_REQUIRED;
    } else {
      setErrorRewardText("Error");
      node.data.price_error = Constants.PRODUCT_RETAIL_PRICE_MIN_MAX_ERROR;
    }

    // Recursively update children nodes
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        updateNodeCashPrice(child, roundedValue); // Pass down the same value to children
        // updateNodeSalePrice(child, calculateDiscountedPrice(roundedValue,child.cash_discount_percentage)); // Pass down the same value to children
      });
    }
  };

  const isValidSalePrice = (price) => {
    // Remove leading and trailing whitespace from the input
    const trimmedPrice = parseFloat(price).toFixed(2);
    // Use a regular expression to match a valid decimal number format
    const decimalRegex = /^\d{0,8}(\.\d{1,2})?$/;
    // Check if the trimmed price matches the decimal format
    if (!decimalRegex.test(trimmedPrice)) {
      return false; // Not a valid decimal number
    }
    // Convert the trimmed price to a number
    const numericPrice = parseFloat(trimmedPrice);
    // Check if the numeric price falls within the specified range
    return numericPrice >= 0.01 && numericPrice <= 999999.99;
  };

  // Create Product Close Model.
  const createModelClose = () => {
    onClose();
    setCreateFlag(false);
  };
  // Edit Product Close Model.
  const editModelClose = () => {
    onEditClose();
    setId(0);
    setEditFlag(false);
  };
  // Duplicate Product Close Model.
  const duplicateModelClose = () => {
    onDuplicateClose();
    setId(0);
    setDuplicateFlag(false);
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <ProductCreate
        fetchData={fetchData}
        isOpen={isOpen}
        placement="right"
        onClose={createModelClose}
        onOpen={onOpen}
        finalFocusRef={btnRef}
        createFlag={createFlag}
      />
      {/* <ProductEdit
        fetchData={fetchData}
        isEditOpen={isEditOpen}
        placement="right"
        onEditClose={editModelClose}
        onEditOpen={onEditOpen}
        finalFocusRef={btnRef}
        editFlag={editFlag}
        id={id}
      /> */}
      <ProductEditDrawer
        fetchData={fetchData}
        isEditOpen={isEditOpen}
        placement="right"
        onEditClose={editModelClose}
        onEditOpen={onEditOpen}
        finalFocusRef={btnRef}
        editFlag={editFlag}
        id={id}
      />
      <ProductDuplicate
        fetchData={fetchData}
        isDuplicateOpen={isDuplicateOpen}
        placement="right"
        onDuplicateClose={duplicateModelClose}
        onDuplicateOpen={onDuplicateOpen}
        finalFocusRef={btnRef}
        duplicateFlag={duplicateFlag}
        id={id}
      />
      <ProductView
        isViewOpen={isViewOpen}
        placement="right"
        onViewClose={onViewClose}
        onViewOpen={onViewOpen}
        finalFocusRef={btnRef}
        id={id}
      />
      <Flex
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        alignItems={"flex-start"}
        justify="space-between"
        pb={16}
      >
        <PosListHeader
          header={"Products"}
          discription={
            "Optimize your sales operations with a powerful product catalogue system"
          }
        />
        <Spacer />
        {!state.firstLayout ? (
          <ButtonGroup direction={"row"} alignSelf={"flex-end"}>
            <Tippy
              content="Use this feature to import your products into Octopos or export them out of Octopos."
              interactive={true}
              maxWidth={390}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image
                src={toolTip}
                alt="Tooltip"
                ml={{ base: "0rem", md: "0.3rem" }}
              />
            </Tippy>
            <PosFormButton
              buttonsubmit={"Import/Export"}
              onClick={() => {
                let data = {
                  categories: JSON.stringify(selectedCategories),
                  locations: JSON.stringify(selectedLocations),
                  vendors: JSON.stringify(selectedVendors),
                  limit: rowsPerPage,
                  page: page,
                  order: "id",
                  order_type: "desc",
                  filter: searchQuery,
                  apply: applyButtonClicked,
                  showFilter: showFilter,
                };
                setSortField(null);
                setSortOrder(null);
                myContext.handleLoading(true); // loading start
                if (
                  (data.categories && data.locations && data.vendors) ||
                  data.filter
                ) {
                  // If all are present, navigate to the stateis page
                  props.navigate(Constants.PRODUCT_IMPORT_EXPORT_PATH, {
                    state: data,
                  });
                } else {
                  // If any are missing, navigate to the create page
                  props.navigate(Constants.PRODUCT_IMPORT_EXPORT_PATH);
                }
              }}
              SubmitButton={true}
              width={"7.5rem"}
            />
            <PosIconButton
              name={"Create"}
              // ref={btnRef}
              // onClick={onOpen}
              onClick={() => {
                myContext.handleLoading(true); // loading start
                // onOpen();
                setCreateFlag(true);
              }}
              width={"7.5rem"}
            />
          </ButtonGroup>
        ) : null}
      </Flex>
      <Box
        // pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        {state.firstLayout ? (
          <Stack
            align="center"
            justify={"center"}
            bg={"#FFF"}
            pt={"4.4rem"}
            pb={"4.4rem"}
          >
            <Box>
              <Image
                w={64}
                h={64}
                src={ProductListEmptyImage}
                alt="Product Image"
              />
            </Box>
            <Box pt={8} pb={8} maxW={"35.88rem"} mx="auto">
              <Text
                color={"#010048"}
                align={"center"}
                fontSize={"1.13rem"}
                fontStyle={"normal"}
                fontWeight={"medium"}
                lineHeight={"normal"}
                letterSpacing={"-0.02rem"}
              >
                {"Oops! It looks like you haven't added any products yet."}
              </Text>
              <Text
                color={"#010048"}
                align={"center"}
                mt={2}
                fontSize={"0.94rem"}
                fontWeight={300}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
              >
                {
                  "To get started - click the 'Create Product' button below and begin crafting your new product. Once added, your products will be organized and ready for management."
                }
              </Text>
            </Box>
            <Stack
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "center", md: "center" }}
            >
              <ButtonGroup gap="2">
                <PosIconButton
                  name="Create Product"
                  SubmitButton={true}
                  // ref={btnRef}
                  // onClick={onOpen}
                  onClick={() => {
                    myContext.handleLoading(true); // loading start
                    // onOpen();
                    setCreateFlag(true);
                  }}
                />
              </ButtonGroup>
              <PosFormButton
                onClick={() => {
                  myContext.handleLoading(true); // loading start
                  props.navigate(Constants.PRODUCT_IMPORT_EXPORT_PATH);
                }}
                buttonsubmit={"Import Product"}
                SubmitButton={true}
              />
            </Stack>
          </Stack>
        ) : (
          <Box
            borderRadius="0.63rem"
            bg="white"
            pt={1}
            boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
          >
            <Tabs onChange={(index) => handleTabChange(index)}>
              <PosTab tabNames={tabNames} />
              {curruntTab == 0 ? (
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems={{ base: "stretch", md: "center" }}
                  // gap={{ base: 2, md: 0 }}
                  mt={4}
                  // mb={3}
                  ml={4}
                  mr={4}
                >
                  <PosSearch
                    value={searchQuery}
                    // onChange={(e) => {
                    //   let value =
                    //     searchQuery.length > 0
                    //       ? e.target.value
                    //       : e.target.value.trim();
                    //     setSearchQuery(value);
                    //     setPage(0);
                    //     apply();
                    // }}
                    onChange={onChange}
                    onSearch={handleSearchList}
                    handleClear={handleClear}
                  />

                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    w={"3rem"}
                    ml={{ base: 0, md: "1rem" }}
                    mt={{ base: "1rem", md: 0 }}
                  >
                    <IconButton
                      onClick={() => {
                        showDropDown();
                      }}
                      // isRound={false}
                      style={{
                        border: "1px solid #ccc",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                        variant: "posoutline",
                        color: Colors.posTextInfo,
                        borderColor: Colors.dividerColor,
                        borderRadius: "5px",
                        padding: 5,
                      }}
                    >
                      <FilterListIcon style={{ color: Colors.posTextInfo }} />
                    </IconButton>
                  </Box>
                </Flex>
              ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems={{ base: "stretch", md: "center" }}
                  gap={{ base: 2, md: 0 }}
                  mt={6}
                  mb={3}
                  ml={4}
                  mr={4}
                >
                  <PosSearch
                    value={searchQuery}
                    onChange={(e) => {
                      let value =
                        searchQuery.length > 0
                          ? e.target.value
                          : e.target.value.trim();
                      setSearchQuery(value);
                    }}
                    onSearch={handleSearchList}
                    handleClear={handleClear} // clear input value and get all data
                  />
                  <Spacer />
                </Flex>
              ) : null}
              {curruntTab == 0 ? (
                <>
                  {showFilter ? (
                    <SimpleGrid
                      columns={{ base: 1, sm: 1, md: 1, lg: 3, xl: 4 }}
                      // minChildWidth='150px'
                      // spacing='20px'
                      ml={4}
                      mr={4}
                      spacing={{
                        base: "1rem",
                        sm: "1rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1rem",
                      }}
                    >
                      <Box mt="1rem">
                        {/* <Text mt="1rem" fontSize={"0.94rem"} fontWeight={300}>
                        Categories:
                      </Text> */}
                        <PosDropDown
                          options={categories}
                          value={selectedCategories}
                          onChange={(e) => {
                            clickIfapplyCat(e.value);
                          }}
                          // onChange={(e) => setSelectedCategories(e.value)}
                          multiSelect={true}
                          optionLabel="label"
                          placeholder="Categories"
                          lableAvailable={true}
                          width={"100%"}
                          height={"2.5rem"}
                          className="w-full md:w-20rem"
                          // display={"chip"}
                          maxSelectedLabels={2}
                        />
                      </Box>
                      {locations?.length > 1 ? (
                        <Box mt="1rem">
                          {/* <Text mt="1rem" fontSize={"0.94rem"} fontWeight={300}>
                          Locations:
                        </Text> */}
                          <PosDropDown
                            options={locations}
                            value={selectedLocations}
                            onChange={(e) => {
                              clickIfapplyLoc(e.value);
                            }}
                            multiSelect={true}
                            optionLabel="label"
                            placeholder="Locations"
                            lableAvailable={true}
                            width={"100%"}
                            height={"2.5rem"}
                            className="w-full md:w-20rem"
                            // display={"chip"}
                            maxSelectedLabels={2}
                          />
                        </Box>
                      ) : null}

                      <Box mt="1rem">
                        {/* <Text mt="1rem" fontSize={"0.94rem"} fontWeight={300}>
                          Vendors:
                        </Text> */}
                        <PosDropDown
                          options={vendors}
                          value={selectedVendors}
                          onChange={(e) => {
                            clickIfapplyVen(e.value);
                          }}
                          // onChange={(e) => setSelectedVendors(e.value)}
                          multiSelect={true}
                          optionLabel="label"
                          placeholder="Vendors"
                          lableAvailable={true}
                          width={"100%"}
                          height={"2.5rem"}
                          className="w-full md:w-20rem"
                          // display={"chip"}
                          maxSelectedLabels={2}
                        />
                      </Box>

                      <Box alignContent={"end"} mt={{ base: 2, md: 0 }}>
                        <ButtonGroup>
                          {/* <PosFormButton
                          isDisabled={
                            selectedLocations.length > 0 ||
                            selectedCategories.length > 0 ||
                            selectedVendors.length > 0
                              ? false
                              : true
                          }
                          buttonsubmit={"Apply"}
                          SubmitButton={true}
                          onClick={() => {
                            apply();
                          }}
                        /> */}
                          <PosFormButton
                            isDisabled={
                              (searchQuery.length > 0 ||
                                selectedLocations.length > 0 ||
                                selectedCategories.length > 0 ||
                                selectedVendors.length > 0) &&
                              applyButtonClicked == 1
                                ? false
                                : true
                            }
                            onClick={() => {
                              clearDropDown();
                            }}
                            buttonText={"Clear"}
                            CancelButton={true}
                          />
                        </ButtonGroup>
                      </Box>
                    </SimpleGrid>
                  ) : null}
                  {Object.keys(selectedNodeKeys)?.length > 0 ? (
                    <Flex
                      direction={"row"}
                      justifyContent={"flex-start"}
                      gap={{ base: 2, md: 0 }}
                      mt={4}
                      // mb={3}
                      ml={4}
                      mr={4}
                    >
                      <PosOutlineButton
                        isDisabled={
                          selectedNodeKeys != null &&
                          Object.keys(selectedNodeKeys).length > 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          changeSalePricePopUpOpen();
                        }}
                        name="Bulk Update"
                      ></PosOutlineButton>
                    </Flex>
                  ) : null}
                  <Modal
                    isCentered
                    size={"xl"}
                    isOpen={isQuickEditOpen}
                    onClose={onQuickEditClose}
                    scrollBehavior={"inside"}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader
                        color={"#010048"}
                        fontSize={"1.13rem"}
                        fontWeight={500}
                        textAlign={"center"}
                      >
                        Quick Edit Product
                      </ModalHeader>
                      {/* <ModalCloseButton /> */}
                      <Divider />

                      <ModalBody>
                        <Box>
                          <PosLable requiredLabel={true} name={"Name"} />
                          <PosInput
                            id="name"
                            placeholder={"Enter a name"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.name}
                            handleBlur={formik.handleBlur}
                            posInput={true}
                            inputType={"text"}
                            inputError={
                              formik.touched.name && formik.errors.name
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.name && formik.errors.name ? (
                              <span>{formik.errors.name}</span>
                            ) : null}
                          </Text>
                        </Box>
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable
                              requiredLabel={true}
                              name={"Primary Barcode (ISBN, UPC, GTIN)"}
                            />
                            <Tippy
                              content="This is the Barcode that will be associated with the specific product. The system will ring up the associated Product when this barcode is ringed up."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={{ base: "0", md: "2rem" }}
                                ml={{ base: "0rem", md: "0.3rem" }}
                              />
                            </Tippy>
                          </Flex>
                          <PosInput
                            id="barcode"
                            placeholder={"Enter a Primary barcode"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.barcode}
                            handleBlur={formik.handleBlur}
                            posInput={true}
                            inputType={"text"}
                            inputError={
                              formik.touched.barcode && formik.errors.barcode
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.barcode && formik.errors.barcode ? (
                              <span>{formik.errors.barcode}</span>
                            ) : null}
                          </Text>
                        </Box>
                        <Box>
                          <PosLable
                            requiredLabel={true}
                            name={"Retail Price"}
                          />
                          <PosInput
                            id="sale_price"
                            placeholder={"Enter a Retail Price"}
                            // handleInputChange={formik.handleChange}
                            handleInputChange={(e) => {
                              formik.setFieldValue(
                                "sale_price",
                                e?.target?.value
                              );
                              formik.setFieldValue(
                                "cash_price",
                                twofix(
                                  reverseCalculateDiscountedPrice(
                                    e?.target?.value,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                            }}
                            inputValue={formik.values.sale_price || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "sale_price",
                                twofix(formik.values.sale_price)
                              );
                              formik.setFieldValue(
                                "cash_price",
                                twofix(
                                  reverseCalculateDiscountedPrice(
                                    formik.values?.sale_price,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.sale_price &&
                              formik.errors.sale_price
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.sale_price &&
                            formik.errors.sale_price ? (
                              <span>{formik.errors.sale_price}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {cashDiscountPercentage > 0 ? (
                          <Box>
                            <PosLable label={true} name={"Cash Price"} />
                            <PosInput
                              id="cash_price"
                              placeholder={"Enter a Cash Price"}
                              // handleInputChange={formik.handleChange}
                              handleInputChange={(e) => {
                                formik.setFieldValue(
                                  "cash_price",
                                  e?.target?.value
                                );
                                formik.setFieldValue(
                                  "sale_price",
                                  twofix(
                                    calculateDiscountedPrice(
                                      e?.target?.value,
                                      cashDiscountPercentage
                                    )
                                  )
                                );
                              }}
                              inputValue={formik.values.cash_price || ""}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldValue(
                                  "cash_price",
                                  twofix(formik.values.cash_price)
                                );
                                formik.setFieldValue(
                                  "sale_price",
                                  twofix(
                                    calculateDiscountedPrice(
                                      formik.values?.cash_price,
                                      cashDiscountPercentage
                                    )
                                  )
                                );
                              }}
                              precision={2}
                              posNumberInput={true}
                              // inputType={"text"}
                              inputError={
                                formik.touched.cash_price &&
                                formik.errors.cash_price
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {formik.touched.cash_price &&
                              formik.errors.cash_price ? (
                                <span>{formik.errors.cash_price}</span>
                              ) : null}
                            </Text>
                          </Box>
                        ) : null}
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable label={true} name={"Reward Points"} />
                            <Tippy
                              content="These are the points a customer will get on purchasing the product."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={{ base: "0", md: "2rem" }}
                                ml={{ base: "0rem", md: "0.3rem" }}
                              />
                            </Tippy>
                          </Flex>
                          <PosInput
                            id="reward_points"
                            placeholder={"Enter a Reward Points"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.reward_points || ""}
                            handleBlur={formik.handleBlur}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.reward_points &&
                              formik.errors.reward_points
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.reward_points &&
                            formik.errors.reward_points ? (
                              <span>{formik.errors.reward_points}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {prodCount > 1 ? (
                          <Box mt={"2rem"}>
                            <Flex flexDirection={"row"} alignItems={"baseline"}>
                              <PosSwitchButton
                                id="edit_for_all_loc"
                                switchValue={formik.values.edit_for_all_loc}
                                onChange={formik.handleChange}
                                w={"44px"}
                                height={"22px"}
                                flexShrink={"0"}
                              />
                              <Text
                                fontSize={"0.94rem"}
                                fontWeight={500}
                                ml={"0.5rem"}
                              >
                                {"Apply changes to all locations."}
                              </Text>
                            </Flex>
                          </Box>
                        ) : null}
                      </ModalBody>
                      <ModalFooter>
                        <ButtonGroup direction={"row"} alignSelf={"flex-end"}>
                          <PosFormButton
                            isDisabled={quickButtonDisable ? true : false}
                            onClick={onQuickEditClose}
                            buttonText={"Cancel"}
                            CancelButton={true}
                          />
                          <PosFormButton
                            isDisabled={
                              !formik.isValid ||
                              !formik.dirty ||
                              formDisable ||
                              quickButtonDisable
                                ? true
                                : false
                            }
                            buttonsubmit={"Submit"}
                            SubmitButton={true}
                            onClick={formik.handleSubmit}
                          />
                        </ButtonGroup>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  <Modal
                    isCentered
                    isOpen={isSalePriceChangeOpen}
                    onClose={onSalePriceChangeClose}
                    size={"6xl"}
                    scrollBehavior={"inside"}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader
                        color={"#010048"}
                        fontSize={"1.13rem"}
                        fontWeight={500}
                        textAlign={"center"}
                        pt={"2.75rem"}
                      >
                        Bulk Update
                      </ModalHeader>
                      <Divider />

                      <ModalBody>
                        <Box bg={"#F4F4F4"}>
                          <TreeTable
                            value={bulkData}
                            expandedKeys={expandedKeys}
                            tableStyle={{
                              minWidth: "50rem",
                              fontFamily: "Lexend, sans-serif",
                            }}
                          >
                            <Column
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                width: "75px",
                              }} // Adjust width as needed
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 300,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                ></TableCell> // Empty header cell
                              }
                              style={{
                                width: "75px",
                              }}
                              expander
                            />
                            <Column
                              field="name"
                              style={{ fontWeight: "500" }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                color: "#111928",
                                paddingLeft: 0, // Remove left padding
                                paddingRight: 0, // Remove right padding
                                // textAlign: "center",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                >
                                  Product Name
                                </TableCell>
                              }
                              // expander
                            />
                            <Column
                              field="barcode"
                              style={{ fontWeight: "300" }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                color: "#111928",
                                paddingLeft: 0, // Remove left padding
                                paddingRight: 0, // Remove right padding
                                // textAlign: "center",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                >
                                  Barcode
                                </TableCell>
                              }
                            />

                            <Column
                              field="reward_points"
                              style={{
                                fontWeight: "500",
                                color: "#1D4FE7",
                                fontSize: "1rem",
                              }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                color: "#111928",
                                paddingLeft: 0, // Remove left padding
                                paddingRight: 0, // Remove right padding
                                // textAlign: "center",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                >
                                  Reward Points
                                </TableCell>
                              }
                              editor={rewardPointsEditor}
                              body={(rowData) =>
                                actionTemplateReward({ rowData })
                              }
                            />
                            <Column
                              field="sale_price"
                              style={{
                                fontWeight: "500",
                                color: "#1D4FE7",
                                fontSize: "1rem",
                              }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                color: "#111928",
                                paddingLeft: 0, // Remove left padding
                                paddingRight: 0, // Remove right padding
                                // textAlign: "center",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                >
                                  Retail Price($)
                                </TableCell>
                              }
                              editor={salePriceEditor}
                              body={(rowData) =>
                                actionTemplateSalePrice({ rowData })
                              }
                            />
                            <Column
                              field="cash_price"
                              style={{
                                fontWeight: "500",
                                color: "#1D4FE7",
                                fontSize: "1rem",
                              }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                color: "#111928",
                                paddingLeft: 0, // Remove left padding
                                paddingRight: 0, // Remove right padding
                                // textAlign: "center",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-1.5%",
                                  }}
                                >
                                  Cash Price($)
                                </TableCell>
                              }
                              editor={cashPriceEditor}
                              body={(rowData) =>
                                actionTemplateCashPrice({ rowData })
                              }
                            />
                          </TreeTable>
                        </Box>
                      </ModalBody>
                      <ModalFooter>
                        <PosFormButton
                          onClick={onSalePriceChangeClose}
                          isDisabled={bulkButtonDisable ? true : false}
                          buttonText={"Cancel"}
                          CancelButton={true}
                        />
                        <PosFormButton
                          isDisabled={bulkButtonDisable ? true : false}
                          buttonsubmit={"Submit"}
                          SubmitButton={true}
                          onClick={changeSalePriceSubmit}
                        />
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </>
              ) : null}

              <TabPanels>
                <TabPanel>
                  {loading ? (
                    <PosProgress />
                  ) : totalRecords > 0 && curruntTab === 0 ? (
                    <TableContainer>
                      <Table style={{ border: "1px solid #E2E8F0" }}>
                        <TreeTable
                          // selectionMode="single"
                          // resizableColumns
                          onSort={onSort}
                          sortField={sortField}
                          sortOrder={sortOrder}
                          value={nodes}
                          selectionMode="checkbox"
                          selectionKeys={selectedNodeKeys}
                          onSelectionChange={(e) => handleSelectionChange(e)}
                          tableStyle={{
                            // minWidth: "50rem",
                            fontFamily: "Lexend, sans-serif",
                          }}
                        >
                          <Column
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              width: isCheckBoxSetLarge
                                ? "108px"
                                : isCheckBoxSetSmall
                                ? "90px"
                                : "108px",
                            }} // Adjust width as needed
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 300,
                                  fontSize: "1rem",
                                  lineHeight: "1.18rem",
                                  letterSpacing: "-1.5%",
                                }}
                              ></TableCell> // Empty header cell
                            }
                            style={
                              {
                                // margin: "1.13rem",
                              }
                            }
                            expander
                          />

                          <Column
                            field="image_url"
                            style={{
                              fontWeight: "500",
                              // display: "flex",
                              flexDirection: "row",
                              // justifyContent:'flex-end',
                              alignItems: "center",
                              // margin: "1.13rem",
                              // margin: "1.13rem",
                            }}
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: isCheckBoxSetLarge
                                ? "108px"
                                : isCheckBoxSetSmall
                                ? "50px"
                                : "108px",
                            }}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 300,
                                  fontSize: "1rem",
                                  lineHeight: "1.18rem",
                                  letterSpacing: "-1.5%",
                                }}
                              ></TableCell>
                            }
                            // expander
                            body={(rowData) => {
                              if (
                                !rowData?.more_data ||
                                rowData?.data?.more_data === 0
                              ) {
                                if (
                                  rowData?.data?.image_url ||
                                  (rowData?.children?.length === 0 &&
                                    rowData?.image_url)
                                ) {
                                  return (
                                    <img
                                      src={
                                        rowData?.data?.image_url ||
                                        rowData?.image_url
                                      }
                                      alt={rowData?.data.name}
                                      style={{
                                        width: "2.69rem",
                                        height: "2.25rem",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <Image
                                    w={"2.69rem"}
                                    h={"2.25rem"}
                                    src={ProductImageNotFound}
                                    alt="Product Image"
                                  />
                                );
                              } else {
                                if (
                                  rowData?.image_url != null &&
                                  rowData?.image_url != undefined &&
                                  rowData?.image_url != ""
                                ) {
                                  return (
                                    <img
                                      src={rowData?.image_url}
                                      alt={rowData?.data.name}
                                      style={{
                                        width: "2.69rem",
                                        height: "2.25rem",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  );
                                } else {
                                  return (
                                    <Image
                                      w={"2.69rem"}
                                      h={"2.25rem"}
                                      src={ProductImageNotFound}
                                      alt="Product Image"
                                    />
                                  );
                                }
                              }
                            }}
                          />

                          <Column
                            field="name"
                            style={{ fontWeight: "500" }}
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: isExtraLarge
                                ? "326px"
                                : isLarge
                                ? "326px"
                                : isNameLarge
                                ? "326px"
                                : isNameMeduim
                                ? "150px"
                                : isNameSmall
                                ? "100px"
                                : isSmall
                                ? "326px"
                                : "326px",
                            }}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 500,
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                  letterSpacing: "-1.5%",
                                  whiteSpace: "normal",
                                }}
                              >
                                Name
                              </TableCell>
                            }
                            // expander
                            sortable
                          />
                          <Column
                            field="barcode"
                            style={{ fontWeight: "300" }}
                            // headerStyle={{ backgroundColor: "#5881fe1a" }}
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: isSmall ? "180px" : "",
                            }}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 500,
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                  letterSpacing: "-1.5%",
                                  whiteSpace: "normal",
                                }}
                              >
                                Barcode
                              </TableCell>
                            }
                            sortable
                          />
                          <Column
                            field="categories"
                            style={{ fontWeight: "300" }}
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: isSmall ? "180px" : "",
                            }}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 500,
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                  letterSpacing: "-1.5%",
                                  whiteSpace: "normal",
                                }}
                              >
                                Category
                              </TableCell>
                            }
                          />
                          <Column
                            field="sale_price"
                            style={{ fontWeight: "300" }}
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: isSmall ? "150px" : "",
                            }}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 500,
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                  letterSpacing: "-1.5%",
                                  whiteSpace: "normal",
                                }}
                              >
                                Retail Price
                              </TableCell>
                            }
                          />
                          {locations?.length > 1 ? (
                            <Column
                              field="location"
                              style={{ fontWeight: "300" }}
                              headerStyle={{
                                backgroundColor: "#5881fe1a",
                                textAlign: "center",
                                width: isSmall ? "150px" : "",
                              }}
                              header={
                                <TableCell
                                  style={{
                                    color: "#010048",
                                    display: "contents",
                                    fontWeight: 500,
                                    fontSize: "1rem",
                                    lineHeight: "normal",
                                    letterSpacing: "-1.5%",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  Location
                                </TableCell>
                              }
                            />
                          ) : null}
                          <Column
                            // header="Actions"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              textAlign: "center",
                              width: "110px",
                            }}
                            body={(rowData) => actionTemplate({ rowData })}
                            header={
                              <TableCell
                                style={{
                                  color: "#010048",
                                  display: "contents",
                                  fontWeight: 500,
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                  letterSpacing: "-1.5%",
                                  whiteSpace: "normal",
                                }}
                              >
                                Actions
                              </TableCell>
                            }
                          />
                        </TreeTable>
                        <TableFooter>
                          <TableRow sx={{ border: "none" }}>
                            <ThemeProvider theme={Mtheme}>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                sx={{
                                  [`& .${tablePaginationClasses.spacer}`]: {
                                    display: "none",
                                  },
                                  [`& .${tablePaginationClasses.toolbar}`]: {
                                    justifyContent: "center",
                                  },
                                  [`& .${tablePaginationClasses.selectLabel}`]: {
                                    color: Colors.posNavbarLink,
                                    fontWeight: 300,
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                    letterSpacing: "-1.5%",
                                  },
                                }}
                                SelectProps={{
                                  inputProps: { "aria-label": "rows per page" },
                                  native: true,
                                }}
                                count={totalRecords}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={<span>Items per page</span>}
                              />
                            </ThemeProvider>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  ) : (
                    <PosNoDataFound title={Constants.PRODUCT_NOT_FOUND} />
                  )}
                </TabPanel>
                <TabPanel>
                  {loading ? (
                    <PosProgress />
                  ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                    <PosDataGridTable
                      columns={deaciveListColumnNames}
                      rows={deactiveProductData}
                      totalcount={deactiveTotalCount}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationModelDeactive}
                      paginationMode="server"
                      sortingMode="server"
                      onPaginationModelChange={(newPaginationModelDeactive) => {
                        effectRun.current = true;
                        setPaginationModelDeactive(newPaginationModelDeactive);
                      }}
                      sortModel={sortModelDeactive}
                      onSortModelChange={(newSortModelDeactive) => {
                        effectRun.current = true;
                        sortRef.current = sortModelDeactive;
                        setSortModelDeactive(newSortModelDeactive);
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.DEACTIVE_PRODUCT_EXPORT_FILENAME}
                    />
                  ) : (
                    <PosNoDataFound
                      title={Constants.DEACTIVE_PRODUCT_NOT_FOUND}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(ProductList);
